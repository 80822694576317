<template>
  <div>
    <content-box title="Registered" class="col-md-7 col-xl-5">
      <div class="mb-3">
        <h3 class="mb-4 text-center">Welcome to the Family!</h3>
        <p>
          You have successfully registered {{ registrants }} for the
          {{ season }} season. We look forward to seeing you at practice! We
          will be in touch shortly, via email, with more information regarding
          our program and payment information.
        </p>
        <p>Thank you!</p>
        <p>- Haverhill Hammers Youth Wrestling</p>
      </div>
    </content-box>
  </div>
</template>

<script>
import contentBox from '../../components/contentBox'

export default {
  name: 'registered',
  components: {
    contentBox,
  },
  props: {
    season: {
      type: String,
      required: true,
    },
    wrestlers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== 'registration') {
      next(false)
    } else {
      next(true)
    }
  },
  computed: {
    registrants() {
      return this.wrestlers
        .map((wrestler) => wrestler.first + ' ' + wrestler.last)
        .join(', ')
    },
  },
  methods: {},
}
</script>

<style scoped></style>
